import * as Styles from '~/routes/AccountCustomize/SwapCutsModal.css'
import { Box, Button, FieldMessage } from '@butcherbox/freezer'
import React, { MouseEventHandler } from 'react'
import { TransitionBox } from 'design/components/Button/ButtonToast'
import {
  FieldMessageTone,
  FieldMessageVariant,
} from '@butcherbox/freezer/dist/components/FieldMessage/FieldMessage.types'
import { ButtonVariant } from '@butcherbox/freezer/dist/components/Button/Button.types'
import { LiveMessage } from 'react-aria-live'

type ButtonWithErrorToastProps = {
  buttonVariant?: ButtonVariant
  durationMs?: number
  error: string
  errorTone?: FieldMessageTone
  errorVariant?: FieldMessageVariant
  onClick?: MouseEventHandler
  onErrorHidden?: () => void
}

// In case there's any kind of timing issue with executing the onErrorHidden callback,
// add some time to the end of the animation to make sure the parent has removed the error.
const DISAPPEAR_BUFFER_MS = 100

const ButtonWithErrorToast: React.FC<ButtonWithErrorToastProps> = ({
  buttonVariant = 'primary',
  children,
  durationMs = 5000,
  error,
  errorTone = 'critical',
  errorVariant = 'block',
  onClick = () => {},
  onErrorHidden = () => {},
}) => {
  const timerRef = React.useRef<number>(null)

  React.useEffect(() => {
    if (error) {
      if (timerRef.current) window.clearTimeout(timerRef.current)

      timerRef.current = window.setTimeout(() => {
        timerRef.current = null
        onErrorHidden()
      }, durationMs)
    }

    return () => window.clearTimeout(timerRef.current)
  }, [error, durationMs, onErrorHidden])

  return (
    <Box className={Styles.ButtonAndError}>
      {error && (
        <Box className={Styles.ButtonError}>
          <TransitionBox duration={durationMs + DISAPPEAR_BUFFER_MS}>
            <LiveMessage
              aria-live="polite"
              clearOnUnmount
              message={'Error: ' + error}
            />
            <FieldMessage
              fieldId=""
              message={error}
              tone={errorTone}
              variant={errorVariant}
            />
          </TransitionBox>
        </Box>
      )}
      <Button onClick={onClick} variant={buttonVariant} width="compact">
        {children}
      </Button>
    </Box>
  )
}

export default ButtonWithErrorToast
