import { IconMinus, IconPlus, rem } from '@butcherbox/freezer'
import { Flex, Text } from '@chakra-ui/core'
import { pluralize } from 'design'
import {
  LargeButtonPrimary,
  LargeButtonSecondary,
  SmallButtonPrimary,
  SmallButtonSecondary,
} from 'design/components/Button/Button'
import ButtonToast from 'design/components/Button/ButtonToast'
import { Body, CTA } from 'design/components/Typography/Typography'
import React from 'react'
import { LiveMessage } from 'react-aria-live'
import { TEST_ID } from '~/constants/cypress'
import {
  ICardQuantityInput,
  IInitialTrigger,
  IQuantityInputs,
} from '~/design/components/CardQuantityInput/CardQuantity.types'
import { useInView } from 'react-intersection-observer'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

const CardQuantityInput: React.FC<ICardQuantityInput> = ({
  boxIsFull,
  buttonProps,
  handleAddItem,
  handleRemoveItem,
  isDisabled = false,
  isLoading = false,
  maxQuantity = 10,
  onBoxFull,
  label,
  quantity = 0,
  product,
  size = 'sm',
  variant,
  errorMessage = '',
  showInfoModalButton = false,
  ...props
}) => {
  const handleRef = React.useRef<number>(undefined)
  const [showError, setShowError] = React.useState(false)
  const [shouldFocus, setShouldFocus] = React.useState<boolean>(false)
  const quantityRef = React.useRef<number>(quantity)

  const [containerRef, isItemVisible] = useInView()

  //to determine whether or not we need to set the focus on the "Add to box" or subtract buttons on component mount, we do a quick check to make sure the quantity has actually changed versus a fresh page load
  React.useEffect(() => {
    // check if item is visible in case there's multiple instances of an item.
    // this might happen, for instance, if we're showing the "popular cuts" section.
    if (isItemVisible && quantityRef.current !== quantity && quantity === 1) {
      setShouldFocus(true)
    }
    quantityRef.current = quantity
  }, [isItemVisible, quantity])

  const showErrorWithTimer = React.useCallback(() => {
    setShowError(true)
    if (handleRef.current) window.clearTimeout(handleRef.current)
    handleRef.current = window.setTimeout(() => {
      handleRef.current = null
      setShowError(false)
    }, 5000)
  }, [])

  const handleMaxQuantityReached = React.useCallback(() => {
    // max reached for a single cut; or no custom behavior provided
    if (!boxIsFull || !onBoxFull) {
      showErrorWithTimer()
    } else {
      onBoxFull(product)
    }
  }, [boxIsFull, onBoxFull, product, showErrorWithTimer])

  const addItem = React.useCallback(() => {
    if (isDisabled) return
    if (props.subscriptionStatus === 'past_due') {
      props.displayPastDueModal(true)
      return
    }
    if (quantity + 1 <= maxQuantity) {
      handleAddItem()
    } else {
      handleMaxQuantityReached()
    }
  }, [
    handleAddItem,
    maxQuantity,
    quantity,
    isDisabled,
    handleMaxQuantityReached,
    props,
  ])

  const removeItem = React.useCallback(() => {
    if (quantity - 1 >= 0 && !isDisabled) {
      handleRemoveItem()
    }
  }, [handleRemoveItem, quantity, isDisabled])

  React.useEffect(() => {
    if (quantity < maxQuantity && showError) setShowError(false)
  }, [maxQuantity, quantity, showError])

  React.useEffect(() => () => window.clearTimeout(handleRef.current), [])
  const infoModalButtonStyle = {
    backgroundColor: 'bb.spicedCrimson',
    color: 'white',
    px: rem(32),
    py: rem(12),
    _hover: { bg: 'bb.crimson' },
  }
  const infoModalButtonStyleProps = showInfoModalButton
    ? infoModalButtonStyle
    : null
  const sharedProps = React.useMemo(
    () => ({
      'aria-disabled': isDisabled || quantity >= maxQuantity,
      isDisabled: isDisabled || quantity >= maxQuantity,
      'aria-label': isDisabled
        ? `No units of ${product.description} can be added to your ${getTarget(
            variant
          )}`
        : `Add 1 unit of ${product.description} to your ${getTarget(
            variant
          )}, current quantity 0`,
      'data-cy': TEST_ID.BUTTON_ADD_TO_BOX,
      'data-cy-at-max-quantity': quantity >= maxQuantity,
      'data-what': 'quantity-initial-add-to-box',
      display: 'block',
      isLoading: isLoading,
      onClick: addItem,
      width: '100%',
      ...buttonProps,
      ...infoModalButtonStyleProps,
    }),
    [
      addItem,
      buttonProps,
      isDisabled,
      isLoading,
      maxQuantity,
      product.description,
      quantity,
      variant,
      infoModalButtonStyleProps,
    ]
  )

  const Toast = showError && (
    <ButtonToast
      alignItems="center"
      error={!!errorMessage ? errorMessage : 'Max quantity in box'}
      width="inherit"
    />
  )

  return (
    <Flex
      alignItems="center"
      data-what="quantity-input-wrapper"
      justifyContent="center"
      m="auto"
      maxWidth="100%"
      pos="relative"
      ref={containerRef}
      textAlign="center"
      w="100%"
      {...props}
    >
      {Toast}
      {quantity === 0 ? (
        <InitialTrigger
          isDisabled={isDisabled}
          isLoading={isLoading}
          label={label}
          quantity={quantity}
          sharedProps={sharedProps}
          shouldFocus={shouldFocus}
          showInfoModalButton={showInfoModalButton}
          size={size}
          variant={variant}
        />
      ) : (
        <QuantityInputs
          handleAddItem={addItem}
          handleRemoveItem={removeItem}
          isDisabled={isDisabled}
          isLoading={isLoading}
          maxQuantity={maxQuantity}
          product={product}
          quantity={quantity}
          shouldFocus={shouldFocus}
          size={size}
          target={getTarget(variant)}
          w="inherit"
        />
      )}
    </Flex>
  )
}

const InitialTrigger: React.FC<IInitialTrigger> = ({
  variant,
  isDisabled,
  showInfoModalButton,
  isLoading,
  sharedProps,
  size,
  label,
  quantity,
  shouldFocus,
}) => {
  const addOnTriggerRef = React.useRef<HTMLButtonElement>(null)
  const ButtonSecondary =
    size === 'lg' ? LargeButtonSecondary : SmallButtonSecondary

  React.useEffect(() => {
    if (shouldFocus) {
      addOnTriggerRef.current && addOnTriggerRef?.current.focus()
    }
  }, [shouldFocus, quantity])

  switch (variant) {
    case 'addon':
    case 'customCut':
      return (
        <CTA
          _hover={{ color: 'bb.crimson' }}
          as="button"
          color="bb.spicedCrimson"
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          tabIndex={0}
          textAlign="inherit"
          {...sharedProps}
          ref={addOnTriggerRef}
        >
          {isLoading ? (
            <LoadingSpinner size={'small'} />
          ) : label !== undefined ? (
            label
          ) : showInfoModalButton ? (
            'Add to box'
          ) : (
            '+ Add to box'
          )}
        </CTA>
      )

    case 'featuredDeal':
      return (
        <LargeButtonPrimary {...sharedProps} innerRef={addOnTriggerRef}>
          Add to Next Box
        </LargeButtonPrimary>
      )

    default:
      return (
        <ButtonSecondary {...sharedProps} innerRef={addOnTriggerRef}>
          {label || `Add to ${getTarget(variant)}`}
        </ButtonSecondary>
      )
  }
}

const QuantityInputs: React.FC<IQuantityInputs> = ({
  children,
  handleAddItem,
  handleRemoveItem,
  isDisabled = false,
  quantity,
  maxQuantity = 10,
  product,
  shouldFocus,
  size = 'sm',
  target,
  ...props
}) => {
  const buttonSize = size === 'sm' ? rem(32) : rem(48)
  const subtractButton = React.useRef<HTMLButtonElement>(null)

  React.useEffect(() => {
    if (shouldFocus) {
      subtractButton.current && subtractButton.current.focus()
    }
  }, [shouldFocus])

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      position="relative"
      textAlign="center"
      {...props}
    >
      <SmallButtonPrimary
        _disabled={{
          backgroundColor: 'bb.spicedCrimson',
          color: 'white',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        }}
        aria-disabled={isDisabled}
        aria-label={
          isDisabled
            ? `No units of ${product.description} can be removed from your ${target}`
            : `Remove 1 unit of ${product.description} from your ${target}, current quantity ${quantity}`
        }
        data-cy={TEST_ID.BUTTON_DECREMENT}
        data-what="quantity-decrement"
        height={buttonSize}
        innerRef={subtractButton}
        minWidth={buttonSize}
        mr={rem(8)}
        onClick={handleRemoveItem}
        p="0"
        width={buttonSize}
      >
        <IconMinus customColor={{ base: 'white' }} size={'text'} />
      </SmallButtonPrimary>
      <Flex
        align="center"
        bg="white"
        border="1px"
        borderColor="bb.silt"
        flex="1 1 50%"
        height={size === 'sm' ? rem(32) : rem(48)}
        justify="center"
      >
        <Body
          aria-label={`You currently have ${quantity} ${pluralize(
            quantity,
            'unit'
          )} of ${product.description} in your ${target}`}
          data-cy={TEST_ID.PRODUCT_QUANTITY}
          fontSize="sm"
          mb="0"
          pt="0"
          role="status"
        >
          Qty.{' '}
          <Text
            as="strong"
            display="inline-block"
            textAlign="center"
            width={rem(16)}
          >
            {quantity}
          </Text>
        </Body>
        <LiveMessage
          aria-live="assertive"
          clearOnUnmount
          message={`${product.description} quantity ${quantity}`}
        />
      </Flex>
      <SmallButtonPrimary
        _disabled={{
          backgroundColor: 'bb.spicedCrimson',
          color: 'white',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        }}
        aria-disabled={isDisabled || quantity >= maxQuantity}
        aria-label={
          isDisabled || quantity >= maxQuantity
            ? `No more units of ${product.description} can be added to your ${target}`
            : `Add 1 unit of ${product.description} to your ${target}, current quantity ${quantity}`
        }
        data-cy={TEST_ID.BUTTON_INCREMENT}
        data-cy-at-max-quantity={quantity >= maxQuantity}
        data-what="quantity-increment"
        height={buttonSize}
        minWidth={buttonSize}
        ml={rem(8)}
        onClick={handleAddItem}
        p="0"
        width={buttonSize}
      >
        <IconPlus customColor={{ base: 'white' }} size={'text'} />
      </SmallButtonPrimary>
      {children}
    </Flex>
  )
}

const getTarget = (variant: ICardQuantityInput['variant']) => {
  switch (variant) {
    case 'customCut':
    case 'addon':
      return 'box'
    case 'checkoutDeal':
      return 'box'
    case 'deal':
    case 'featuredDeal':
      return 'next box'
    default:
      return 'cart'
  }
}

export default CardQuantityInput
