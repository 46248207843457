import { Box, List, Text } from '@butcherbox/freezer'
import { rem } from 'design'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { graphql, useStaticQuery } from 'gatsby'
import find from 'lodash.find'
import React from 'react'
import { BoxSizeDefinition } from '~/bb-api/schemata'
import { BaseCustomizeProps } from '~/components/BaseCustomize/BaseCustomize.types'
import { SaveButton } from '~/components/BaseCustomize/SaveButton'
import { TEST_ID } from '~/constants/cypress'
import { SubscriptionContext } from '~/context/subscription'
import BoxDetails from './BoxDetails'
import * as Styled from './CustomizeYourBoxHeader.styles'
interface CustomizeYourBoxHeaderProps {
  handleSave: BaseCustomizeProps['handleSave']
  handleSaveStatus: BaseCustomizeProps['handleSaveStatus']
  customBoxSizes: BoxSizeDefinition[]
}

const CustomizeYourBoxHeader = ({
  handleSave,
  handleSaveStatus,
  customBoxSizes,
}: CustomizeYourBoxHeaderProps) => {
  const { subscription } = React.useContext(SubscriptionContext)

  const imageData = useStaticQuery(graphql`
    query {
      bg: contentfulImageSet(uniqueId: { eq: "custom-box-hero" }) {
        ...ImageSet
      }
    }
  `)

  const recommendedCustomBoxSize = find<BoxSizeDefinition>(customBoxSizes, [
    'size',
    subscription.box.size,
  ])

  return (
    <Box id={Styled.HERO_ID}>
      <Styled.HeroImage
        breakpoints={{ mobile: '(max-width: 767px)' }}
        imageSet={imageData.bg}
      ></Styled.HeroImage>
      <Styled.Wrapper>
        <Styled.TextContainer>
          <Text marginBottom={8} variant="Eyebrow">
            Change your box
          </Text>
          <Text marginBottom={4} variant="H1Bold">
            Try the Custom Box!
          </Text>
          <Box position={'relative'}>
            <List variant="unordered">
              <Text variant="Body1Regular">
                Select from over
                <Styled.Mark marginLeft={3} marginRight={3}>
                  25+ cuts{' '}
                </Styled.Mark>
                to fit your needs
              </Text>

              <Text variant="Body1Regular">
                <Styled.Mark marginLeft={0} marginRight={3}>
                  Flexibility{' '}
                </Styled.Mark>
                to try new cuts in every box{' '}
              </Text>
              <Text variant="Body1Regular">
                Add{' '}
                <Styled.Mark marginLeft={0} marginRight={0}>
                  Wild Caught Seafood
                </Styled.Mark>{' '}
                to every box
              </Text>
            </List>
          </Box>

          {recommendedCustomBoxSize ? (
            <BoxDetails
              price={recommendedCustomBoxSize.price}
              weight={recommendedCustomBoxSize.poundage.split(' ')[0]}
            />
          ) : (
            <LoadingSpinner size={'small'} />
          )}

          <SaveButton
            data-cy={TEST_ID.BUTTON_CUSTOMIZE_SAVE}
            handleSave={handleSave}
            handleSaveStatus={handleSaveStatus}
            marginRight="auto"
            minWidth={rem(264)}
            saveButtonText={
              subscription.box.type !== 'cst'
                ? 'Save & Switch to custom'
                : 'Save'
            }
            w="auto"
          />
        </Styled.TextContainer>
      </Styled.Wrapper>
    </Box>
  )
}

export default CustomizeYourBoxHeader
