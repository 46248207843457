import { globalHistory } from '@reach/router'
import { navigate } from 'gatsby'
import React from 'react'
import * as schemata from '~/bb-api/schemata'
import { StagedSubscriptionContext } from '~/context/stagedSubscription'
import { SubscriptionContext } from '~/context/subscription'
import duplicateItemsByQuantity from '~/utils/duplicateItemsByQuantity'

export function unloadListener(event: BeforeUnloadEvent) {
  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
   */
  event.preventDefault()
  event.returnValue = ''
}

export function areBoxItemsEqual(
  itemsA: schemata.BoxItem[],
  itemsB: schemata.BoxItem[]
): boolean {
  const repeatedSkuGenerator = (items: schemata.BoxItem[]): string[] =>
    duplicateItemsByQuantity(items)
      .map((item) => item.sku)
      .sort()

  const arraysAreEqual = (a: string[], b: string[]) =>
    a.length === b.length && a.every((item, idx) => item === b[idx])

  return arraysAreEqual(
    repeatedSkuGenerator(itemsA),
    repeatedSkuGenerator(itemsB)
  )
}

const useUnloadHandlerOnStagedBoxItemUpdate = () => {
  const { subscription } = React.useContext(SubscriptionContext)

  const { stagedSubscription: untypedStagedSubscription } = React.useContext(
    StagedSubscriptionContext
  )
  const stagedSubscription = untypedStagedSubscription as schemata.Subscription

  React.useEffect(() => {
    const boxItemsHaveChanged = !areBoxItemsEqual(
      subscription.box.items,
      stagedSubscription.box.items
    )

    let unsubscribeHistoryListener = () => {}

    if (boxItemsHaveChanged) {
      window.addEventListener('beforeunload', unloadListener)

      unsubscribeHistoryListener = globalHistory.listen(
        ({ action, location }) => {
          if (
            (action === 'PUSH' &&
              !location.pathname.includes('/account/customize-your-box')) ||
            (action === 'POP' &&
              !location.pathname.includes('/account/customize-your-box'))
          ) {
            if (
              !window.confirm(
                'You haven’t saved your Custom Box edits. Are you sure you want to leave this page?'
              )
            ) {
              navigate('/account/customize-your-box')
              history.replaceState(null, null, '/account/customize-your-box')
            }
          }
        }
      )
    }

    return () => {
      window.removeEventListener('beforeunload', unloadListener)
      unsubscribeHistoryListener()
    }
  }, [stagedSubscription.box.items, subscription.box.items])
}

export default useUnloadHandlerOnStagedBoxItemUpdate
