import {
  Box,
  Modal,
  ModalBody,
  ModalFooter,
  OverlayContainer,
  Text,
} from '@butcherbox/freezer'
import { SimpleCheckedCircle } from 'design/components/SimpleCheckedCircle/SimpleCheckedCircle'
import { LayoutContext } from 'design/contexts/layout'
import React, { MouseEventHandler } from 'react'
import { BoxItem } from '~/bb-api/schemata'
import ButtonWithErrorToast from '~/routes/AccountCustomize/ButtonWithErrorToast'
import * as Styles from './SwapCutsModal.css'

type SwapCutCardProps = {
  onClick: MouseEventHandler
  checked: boolean
  item: BoxItem
}

const SwapCutCard = ({ onClick, item, checked }: SwapCutCardProps) => (
  <Box
    border={checked ? 'spicedCrimson' : 'silt'}
    className={Styles.Card}
    onClick={onClick}
  >
    <Box
      className={Styles.ProductImage}
      style={{
        backgroundImage: `url(${item.image})`,
      }}
    />
    <Box className={Styles.CardContent}>
      <Box>
        <Text color="slate" variant="H4Condensed">
          {item.description}
        </Text>
        <Text color="stone" variant="Body2Regular">
          {item.packSize}
        </Text>
      </Box>
      <SimpleCheckedCircle
        checked={checked}
        // currentColor allows the icon to inherit color on hover
        color="currentColor"
      />
    </Box>
  </Box>
)

type SwapCutsModalProps = {
  flatBoxItems: BoxItem[]
  onClose: () => void
  onConfirm: (sku: BoxItem['sku']) => void
}

const ERROR = 'Choose a cut to swap from your box.'

export const SwapCutsModal = ({
  flatBoxItems,
  onClose,
  onConfirm,
}: SwapCutsModalProps) => {
  const [error, setError] = React.useState<string>(null)
  const showError = () => {
    if (!error) setError(ERROR)
  }
  const clearError = React.useCallback(() => setError(null), [])
  const { isMobile } = React.useContext(LayoutContext)
  const itemsWithUniqueSkus = React.useMemo(() => {
    return flatBoxItems.map(({ sku, ...item }, i) => ({
      ...item,
      sku: `${sku}-${i}`,
    }))
  }, [flatBoxItems])

  const [selectedItemSku, setSelectedItemSku] = React.useState<BoxItem['sku']>(
    null
  )
  const submit = () => {
    if (!selectedItemSku) {
      showError()
    } else {
      onConfirm(selectedItemSku.split('-')[0])
    }
  }

  return (
    <OverlayContainer>
      <Modal
        id="swap-modal"
        onClose={onClose}
        size={isMobile ? 'm' : 'fit'}
        title="Swap this cut?"
      >
        <ModalBody paddingTop={8}>
          <Box className={Styles.ModalBody}>
            <Text textAlign="center" variant="Body1Regular">
              Box limit reached! Swap out one of your current selections to add
              this new cut.
            </Text>

            <Box className={Styles.Cards}>
              {itemsWithUniqueSkus.map((item) => (
                <SwapCutCard
                  checked={selectedItemSku === item.sku}
                  item={item}
                  key={item.sku}
                  onClick={() => setSelectedItemSku(item.sku)}
                />
              ))}
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <ButtonWithErrorToast
            error={error}
            onClick={submit}
            onErrorHidden={clearError}
          >
            Swap this cut
          </ButtonWithErrorToast>
        </ModalFooter>
      </Modal>
    </OverlayContainer>
  )
}
