import { VisuallyHidden } from '@chakra-ui/core'
import { OptimizelyContext } from '@optimizely/react-sdk'
import { RouteComponentProps, useLocation } from '@reach/router'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { navigate } from 'gatsby'
import qs from 'querystringify'
import React from 'react'
import { trackBoxCustomized } from '~/analytics/events'
import * as schemata from '~/bb-api/schemata'
import { CustomCutsProduct } from '~/bb-api/schemata'
import BaseCustomize from '~/components/BaseCustomize/BaseCustomize'
import {
  StagedSubscriptionContext,
  StagedSubscriptionProvider,
} from '~/context/stagedSubscription'
import { SubscriptionContext } from '~/context/subscription'
import { mutateSubscription } from '~/hooks/mutateSubscription'
import useBoxSizes from '~/hooks/useBoxSizes'
import useCustomerBoxItems from '~/hooks/useCustomerBoxItems'
import useUnloadHandlerOnStagedBoxItemUpdate from '~/hooks/useUnloadHandlerOnStagedBoxItemUpdate'
import { cleanSubscription } from '~/utils/subscription'
import CustomizeYourBoxHeader from './CustomizeYourBoxHeader/CustomizeYouBoxHeader'
import { SwapCutsModal } from './SwapCutsModal'
import useSwapCuts from './useSwapCuts'

type HandleSaveOptions = {
  navigateToDeals: boolean
  onSuccess?: () => void
}

const AccountCustomize: React.FC<RouteComponentProps> = () => {
  const location = useLocation()
  const { isBlocked: subscriptionIsBlocked } = React.useContext(
    SubscriptionContext
  )
  const [
    updateSubscription,
    { status: subscriptionUpdateStatus },
  ] = mutateSubscription('account_pages')
  const showToast = React.useContext(ToastContext)
  const { subscription } = React.useContext(SubscriptionContext)
  const { optimizely } = React.useContext(OptimizelyContext)

  /**
   * This page can update multiple dimensions of a subscription simultaneously (items, size), so we
   * stage the changes and do validation on the staged subscription before we PUT the
   * updates back to the API.
   */
  const {
    stagedSubscription: untypedStagedSubscription,
    updateStagedSubscription,
  } = React.useContext(StagedSubscriptionContext)

  const stagedSubscription = untypedStagedSubscription as schemata.Subscription

  const handleSave = React.useCallback(
    async (
      updatedSubscription: schemata.Subscription,
      options: HandleSaveOptions
    ) => {
      const cleanedSubscription = cleanSubscription(subscription)
      await updateSubscription(updatedSubscription, {
        onError: (e) => {
          const errorMessage =
            e.data.message === 'The periodEndDate must be a date after today.'
              ? 'We started processing your box at 12am ET and it can no longer be edited.'
              : 'There was a problem updating your box items, please wait a few moments and try again'
          showToast('error', {
            children: errorMessage,
          })
        },
        onSuccess: () => {
          showToast('success', {
            children:
              subscription.box.type == 'cst'
                ? 'Your cuts have been updated'
                : 'Nice! You’ve switched to the custom box!',
          })

          const subscriptionSkus = cleanedSubscription.box.items.map(
            (x) => x.sku
          )

          const stagedSubscriptionSkus = updatedSubscription.box.items.map(
            (x) => x.sku
          )

          trackBoxCustomized(
            updatedSubscription.box.items,
            updatedSubscription.box.items.filter(
              (x) => !subscriptionSkus.includes(x.sku)
            ),
            cleanedSubscription.box.items.filter(
              (x) => !stagedSubscriptionSkus.includes(x.sku)
            ) || []
          )

          if (
            stagedSubscription.box.size === 'classic' &&
            updatedSubscription.box.size === 'big'
          ) {
            optimizely?.track('big_size_plan_chosen')
          } else if (
            stagedSubscription.box.size === 'big' &&
            updatedSubscription.box.size === 'classic'
          ) {
            optimizely?.track('classic_size_plan_chosen')
          }

          if (options.navigateToDeals) navigate('/account/deals')
        },
      })
    },
    [
      subscription,
      updateSubscription,
      showToast,
      optimizely,
      stagedSubscription.box.size,
    ]
  )

  const { data: boxSizes = [], status: getBoxSizesStatus } = useBoxSizes('cst')
  /**
   * This gets the eligible products for the specific customer. In the future
   * it will take things into account like distribution center to filter what
   * products are available.
   */
  const {
    data: rawAvailableProducts = {} as schemata.AvailableCustomCuts,
    status: getBoxItemsStatus,
  } = useCustomerBoxItems()

  const [initialized, setInitialized] = React.useState(false)

  useUnloadHandlerOnStagedBoxItemUpdate()

  React.useEffect(() => {
    if (getBoxSizesStatus !== 'success' || initialized) return

    /**
     * When switching box sizes in the edit box size
     * modal, the customer will be automatically redirected into this flow
     * with the new size pre-selected to adjust cuts as necessary.
     *
     * The inspected query param is set in that modal file.
     */
    const params = qs.parse(location.search) as {
      'box-size'?: schemata.BoxSize
    }
    const queryParamSize: schemata.BoxSize = params['box-size']

    let nextSize: schemata.BoxSize = stagedSubscription.box.size

    /**
     * Take the override from the URL if available.
     */
    if (queryParamSize && boxSizes.some((def) => def.size === queryParamSize)) {
      nextSize = params['box-size']
    }

    /**
     * Finally get the staged subscription into the desired state.
     */
    updateStagedSubscription({
      ...stagedSubscription,
      box: { ...stagedSubscription.box, type: 'cst', size: nextSize },
    })

    setInitialized(true)
  }, [
    stagedSubscription.box,
    boxSizes,
    getBoxSizesStatus,
    initialized,
    location.search,
    stagedSubscription,
    updateStagedSubscription,
  ])

  React.useEffect(() => {
    if (subscriptionIsBlocked) navigate('/account/your-box')
  }, [subscriptionIsBlocked])

  if (getBoxItemsStatus === 'error') {
    showToast('error', {
      children:
        'There was a problem loading your box items. Please try again or contact support for help.',
    })
    navigate('/account/your-box')
  }

  // The product whose incrementing caused the box to exceed capacity.
  // This state also serves as a boolean to determine whether to show the modal.
  const [
    overflowProduct,
    setOverflowProduct,
  ] = React.useState<CustomCutsProduct>(null)

  const { flatBoxItems, onAcceptSwap } = useSwapCuts({
    productToSwapFor: overflowProduct,
    onSuccess: React.useCallback(() => setOverflowProduct(null), [
      setOverflowProduct,
    ]),
  })

  return (
    <>
      {!!overflowProduct && (
        <SwapCutsModal
          flatBoxItems={flatBoxItems}
          onClose={() => setOverflowProduct(null)}
          onConfirm={onAcceptSwap}
        />
      )}
      {subscription.box.type !== 'cst' && (
        <CustomizeYourBoxHeader
          customBoxSizes={boxSizes}
          handleSave={(updatedSubscription) =>
            handleSave(updatedSubscription as schemata.Subscription, {
              navigateToDeals: true,
            })
          }
          handleSaveStatus={subscriptionUpdateStatus}
        />
      )}
      <VisuallyHidden>
        <h1>Customize Your Box</h1>
      </VisuallyHidden>
      <BaseCustomize
        getBoxItemsStatus={getBoxItemsStatus}
        handleSave={(updatedSubscription) =>
          handleSave(updatedSubscription as schemata.Subscription, {
            navigateToDeals: true,
          })
        }
        handleSaveStatus={subscriptionUpdateStatus}
        isLoading={!initialized && getBoxItemsStatus === 'loading'}
        onBoxFull={setOverflowProduct}
        productListCategory="customBox-accountCustomizeYourBox"
        rawAvailableProducts={rawAvailableProducts}
        saveButtonText={
          subscription.box.type === 'cst' ? 'Save' : 'Switch to custom'
        }
        showHeaderText={subscription.box.type === 'cst'}
        showSizeToggles={subscription.box.type === 'cst'}
        sidebarSaveButtonText={
          subscription.box.type === 'cst' ? 'Save' : 'Switch to custom'
        }
      />
    </>
  )
}
export const withStagedSubscription = (WrappedComponent) => (props) => {
  return (
    <StagedSubscriptionProvider>
      <WrappedComponent {...props} />
    </StagedSubscriptionProvider>
  )
}

export default withStagedSubscription(AccountCustomize)
