import React from 'react'
import * as schemata from '~/bb-api/schemata'
import { BoxItem, CustomCutsProduct } from '~/bb-api/schemata'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { StagedSubscriptionContext } from '~/context/stagedSubscription'
import useCustomerBoxItems from '~/hooks/useCustomerBoxItems'

const useSwapCuts = ({
  productToSwapFor,
  onSuccess,
}: {
  productToSwapFor: CustomCutsProduct
  onSuccess: () => void
}) => {
  const showToast = React.useContext(ToastContext)
  const {
    data: rawAvailableProducts = {} as schemata.AvailableCustomCuts,
  } = useCustomerBoxItems()

  // for sorting in flatBoxItems
  const allSkus = React.useMemo(
    () =>
      Object.values(rawAvailableProducts)
        .flat()
        .map((p) => p.sku),
    [rawAvailableProducts]
  )

  const {
    stagedSubscription: {
      box: { items },
    },
    decrementBoxItem,
    incrementBoxItem,
  } = React.useContext(StagedSubscriptionContext)

  const flatBoxItems: BoxItem[] = React.useMemo(
    () =>
      items
        .reduce<BoxItem[]>(
          (arr, item) => [...arr, ...Array(item.quantity).fill(item)],
          []
        )
        .sort((a, b) => allSkus.indexOf(a.sku) - allSkus.indexOf(b.sku)),
    [allSkus, items]
  )

  const onAcceptSwap = React.useCallback(
    (skuToRemove: BoxItem['sku']) => {
      const itemToRemove = items.find((item) => skuToRemove === item.sku)
      decrementBoxItem(itemToRemove)
      incrementBoxItem(productToSwapFor)
      onSuccess()
      showToast('success', {
        children: 'Your swap has completed successfully!',
      })
    },
    [
      items,
      incrementBoxItem,
      productToSwapFor,
      onSuccess,
      showToast,
      decrementBoxItem,
    ]
  )

  return { flatBoxItems, onAcceptSwap }
}

export default useSwapCuts
