import React from 'react'
import { Text, Box } from '@butcherbox/freezer'

interface BoxDetialsProps {
  price: number
  weight: string
}
const BoxDetails: React.FC<BoxDetialsProps> = ({ price, weight }) => {
  return (
    <Box
      component="div"
      display={'inlineFlex'}
      marginBottom={24}
      marginTop={12}
    >
      <Box
        alignItems={'flexEnd'}
        borderRight={'silt'}
        component="div"
        display={'inlineFlex'}
        paddingRight={16}
      >
        <Text marginRight={4} variant="H2Bold">
          ${price / 100}
        </Text>
        <Text color="granite" variant="Body2Bold">
          box price
        </Text>
      </Box>
      <Box
        alignItems={'flexEnd'}
        component="div"
        display={'inlineFlex'}
        paddingLeft={16}
      >
        <Text marginRight={4} variant="H2Bold">
          {weight}
        </Text>
        <Text color="granite" variant="Body2Bold">
          lbs
        </Text>
      </Box>
    </Box>
  )
}

export default BoxDetails
