import React from 'react'
import * as Styles from 'design/components/SimpleCheckedCircle/SimpleCheckedCircle.css'
import { VisuallyHidden, rem } from '@butcherbox/freezer'
import type { IconProps } from 'design/components/Icons/types'
import IconRadioLargeChecked from '../Icons/defs/RadioLargeChecked'
import IconRadioLargeUnchecked from '../Icons/defs/RadioLargeUnchecked'

export const SimpleCheckedCircle = ({
  checked,
  color,
  ...iconProps
}: { checked: boolean } & Partial<IconProps>) => (
  <>
    <VisuallyHidden>
      <input checked={checked} readOnly type="checkbox" />
    </VisuallyHidden>
    {checked ? (
      <IconRadioLargeChecked
        className={Styles.Checkbox}
        color={`bb.spicedCrimson`}
        size={rem(36)}
        {...iconProps}
      />
    ) : (
      <IconRadioLargeUnchecked
        className={Styles.Checkbox}
        color={color ?? 'bb.granite'}
        size={rem(36)}
        {...iconProps}
      />
    )}
  </>
)
